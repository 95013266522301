import { useRef } from 'react'
import { Box, Flex, Card } from '../primitives'
import GlobalSearch from './GlobalSearch'
import { useRouter } from 'next/router'
import { useHotkeys } from 'react-hotkeys-hook'
import Link from 'next/link'
import Image from 'next/image'
import { ConnectWalletButton } from 'components/ConnectWalletButton'
import NavItem from './NavItem'
import ThemeSwitcher from './ThemeSwitcher'
import HamburgerMenu from './HamburgerMenu'
import MobileSearch from './MobileSearch'
import { useTheme } from 'next-themes'
import { useMediaQuery } from 'react-responsive'
import { useMarketplaceChain, useMounted } from '../../hooks'
import { useAccount } from 'wagmi'
import CartButton from './CartButton'
import { AccountSidebar } from 'components/navbar/AccountSidebar'

export const NAVBAR_HEIGHT = 81
export const NAVBAR_HEIGHT_MOBILE = 77

const Navbar = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
  const { routePrefix } = useMarketplaceChain()

  if (!isMobile) {
    return null; // Hide Navbar for non-mobile views or adapt as needed
  }

  return (
    <Flex
      css={{
        height: NAVBAR_HEIGHT_MOBILE,
        px: '$4',
        width: '100%',
        borderBottom: '1px solid #000000',
        zIndex: 999,
        background: '#FFF', // Assuming a white background
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
      }}
      align="center"
      justify="center"
    >
      <Link href={`/${routePrefix}/mints/trending`}>
        <Box css={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
          {/* Make sure to update the src path to your logo accordingly */}
          <Image
            src="/logo.png"
            width={36}
            height={36}
            alt="Logo"
            priority
          />
          <Box css={{ marginLeft: '$2', fontSize: '18px' }}>Trending Mints</Box>
        </Box>
      </Link>
    </Flex>
  )
}

export default Navbar
